.scard-container {
    width: 90%;
    border-radius: 5px;
    margin: 10px;
}

.intro-card {
    
}


.c-banner-icon-container {
    width: 250px;
    height: 250px;
    border: 0px solid #038de2;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    

}

.c-banner-icon-circle {
    width: 50%;
    height: 50%;
    border-radius: 50%;
    border: 1px solid red;
    text-align: center;
    background-color: #01304d;
    
}