/* .App{
  position: absolute;
  width: 100%;
  height: 135px;
  left: 69px;
  top: 17px;
  filter: brightness(100%);
} */

body {
  background-color: #01304d;
  font-family: 'Unica One', cursive;
  /* width: 100vw; */
}

header {
  background-color: white;
  height: 12vh;
  
}

ul {
  list-style-type:none
}

.main-banner {
  /* max-width: 1100px; */
  border: 0px solid red;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.App-logo {
  width: 60px;
  margin: 0.7em;
}
