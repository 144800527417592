@import url("https://fonts.googleapis.com/css?family=Raleway:900&display=swap");



#container {
    /* position: absolute; */
    margin: auto;
    /* height: 80pt; */
    filter: url(#threshold) blur(0.6px);
}

#text1,
#text2 {
    position: absolute;
    width: 100%;
    display: inline-block;
    font-family: 'Unica One', cursive;
    font-size: 80pt;
    color:white;
    text-align: center;
    user-select: none;
}
