.c-main-container{
    /* max-width: 100%; */
    margin-left: auto;
    margin-right: auto;
    border:0px solid red;
    height: 100vh;
}

.c-banner-icon-holder{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}